
nav {
    width: 100%;
    height: 45px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
}

