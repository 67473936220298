.ad-place-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad-place-holder h1 {
    color: white;
    font-size: 7rem;
}

.ad-place-holder h1 span {
    color: var(--red-color);
}