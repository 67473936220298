.menu-container {
    background-color: var(--main-bg-color);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.link-nsfw-version {
    margin: 10px 0px 0px 0px;
    color: white;
    cursor: pointer;
    opacity: 0.6;
}

.link-nsfw-version:hover {
    opacity: 1;
}