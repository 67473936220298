
.error-message-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-message-container p {
    text-align: center;
    font-size: 3rem;
    color: white;
    max-width: 90%;
}