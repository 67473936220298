
.post-container {
    height: calc(100%);
    max-height: 100%;
    bottom: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: black;
    flex-shrink: 0;
}

.post-info-container {
    position: absolute;
    bottom: 20px;
    left: 5px;
    width: auto;
    z-index: 3;
    display: flex;
    color: white;
    align-items: flex-start;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
}
.source-button,
.post-title {
    margin: 5px 0 0 5px;
    color: white;
    left: 5px;
    opacity: 0.6;
    max-width: calc(100% - 10px);
}

.source-button {
    cursor: pointer;
    border: 2px solid white;
    padding: 2px 4px;
    border-radius: 5px;
}

.source-button:hover {
    text-decoration: underline;
    opacity: 1;
}

.back-drop-post-blur {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    object-fit: cover;
    scale: 1.3;
}