
.app-title {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
    text-align: center;
    color: var(--text-color);
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.app-title span {
    font-weight: 600;
    color: var(--red-color);
}