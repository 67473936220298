
.load-splash-screen-container {
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--main-bg-color);
}

.load-splash-screen-container p {
    color: white;
    text-align: center;
    max-width: 90%;
}

.load-splash-screen-container h1 {
    font-size: 4rem;
    margin: 0;
    color: white;
    margin-bottom: 20px;
}

.load-splash-screen-container h2 {
    color: white;
}

.load-splash-screen-container h1 span {
    color: var(--red-color);
}

.confirm-over-18 {
    height: 80px;
    max-width: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    cursor: pointer;
    transition: 0.05s;
}

.confirm-over-18:hover {
    opacity: 0.8;
}



.confirm-over-18 h3 {
    text-align: center;
    color: white;
    font-size: 1.8rem;
    margin: 0;
}