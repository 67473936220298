
.gallery-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-gallery-animation-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-gallery-control {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 30%;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
}

.left-gallery-control:hover,
.right-gallery-control:hover {
    opacity: 0.5;
}

.left-gallery-control svg {
    width: 50px;
    height: 50px;
    margin-left: 5px;
}

.right-gallery-control {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 30%;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
}

.right-gallery-control svg {
    width: 50px;
    height: 50px;
    margin-right: 5px;
}

.gallery-counter-indication {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
}

.gallery-counter-indication h3 {
    color: white;
    opacity: 0.6;
    font-weight: 300;
    margin: 0;
}