.App {
    overflow-x: hidden;
    height: 100vh;
    overflow: hidden;
}

.application-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 30px;
    overflow: hidden;
}

