.expanded-image {
    position: fixed;
    z-index: 30;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
}

.expanded-image img {
    width: 100% !important;
    height: 100% !important;
    max-height: 100vh;
    transition: 0.1s;
}

.react-transform-wrapper {
    width: 100% !important;
    height: 100% !important;
    max-width: 100%;
    max-height: 100vh;
}

.react-transform-component {
    height: 100% !important;
    width: 100% !important;
}

.react-transform-component img {
    object-fit: contain;
}

.close-expanded {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    cursor: pointer;
    opacity: 1;
}

.close-expanded:hover {
    opacity: .6;
}