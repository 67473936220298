
:root {
  --main-bg-color: #212121;
  --red-color: rgb(9, 226, 226);
  --secondary-bg-color: #343434;
  --accent-color: #ffffff1e;
  --font-family: 'K2D', sans-serif;
  --text-color: white;
  --alt-accent-color: #1A1A1A;
}


#root,
body {
  margin: 0;
  box-sizing: border-box;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  margin: 2px;
  background: rgba(0, 0, 0, 0.472);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(159, 159, 159, 0.7);
}
