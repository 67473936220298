
.feed {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.inner-feed-wrapper {
    width: 100%;
    overflow: hidden;
    height: 100%;
}